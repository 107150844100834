<template>
  <section class="one-project fade">
    <!--<Loader v-if="loading" />-->
    <div class=" container-padding">
      <section v-if="post" class="block block-padding-right margin-bottom-new farm-width farm-heading-flex">
        <div class="wrap__farm-heading farm-heading-mobile">
          <div class="farm-heading__left">
            <div v-html="post.title" class="farm-heading__title"></div>
            <div v-if="post.badge_enabled === true" class="farm-heading__farm-info">
              <img src="../../assets/img/icon-edition-link.svg" height="24" width="29"/>
              <div class="farm-info__text">
                <a href="https://www.rshb.ru/farmerschool/">
                  Ферма закончила «Школу Фермеров» и получила грант на развитие
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="wrap__farm-heading farm-heading-dextop">
          <div class="farm-heading__left">
            <div class="farm-heading__title">
              {{ post.title }}
              <div v-if="post.badge_enabled === true" class="editions__tooltip">
                <a @click="openPost(item.id)" class="editions__tooltip-link"><span></span></a>
                <div class="editions__tooltip-text">
                  Ферма закончила «Школу Фермеров» и получила грант на развитие
                  <a target="_blank" href="https://www.rshb.ru/farmerschool/">Подробнее о «Школе Фермеров»</a>
                </div>
              </div>
              <!--<img src="../../assets/img/icon-edition-link.svg" height="24" width="29"/>-->
            </div>
            <a target="_blank" class="farm-heading__address" :href="post.address_url">
              <img src="../../assets/img/icon-map.svg" height="24" width="29"/>
              {{ post.address_name }}</a>
          </div>
          <div class="farm-heading__right">
            <div class="farm-heading__share">
              <button @click="shareOpen = !shareOpen" class="toggle" type="button">
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.768799 9.55793C0.768799 7.57523 2.40849 5.95697 4.46676 5.86612L4.64942 5.8621H5.4658C5.82824 5.8621 6.12205 6.14192 6.12205 6.4871C6.12205 6.80351 5.87516 7.06501 5.55485 7.10639L5.4658 7.1121H4.64942C3.28189 7.1121 2.16378 8.13045 2.08566 9.41423L2.0813 9.55793L2.0813 13.6204C2.0813 14.9221 3.15066 15.9869 4.49855 16.0613L4.64942 16.0654H14.3882C15.7556 16.0654 16.8738 15.0472 16.9519 13.7641L16.9563 13.6204V9.5496C16.9563 8.2518 15.8909 7.1904 14.5481 7.11624L14.3978 7.1121H13.5727C13.2102 7.1121 12.9164 6.83228 12.9164 6.4871C12.9164 6.17068 13.1633 5.90919 13.4836 5.8678L13.5727 5.8621H14.3978C16.4726 5.8621 18.1665 7.41777 18.2643 9.37097L18.2688 9.5496V13.6204C18.2688 15.6025 16.629 17.2206 14.5708 17.3114L14.3882 17.3154H4.64942C2.56775 17.3154 0.868428 15.754 0.773023 13.7943L0.768799 13.6204L0.768799 9.55793ZM9.04393 0.39436C9.16344 0.275129 9.33196 0.200848 9.51869 0.200848C9.70659 0.200848 9.87604 0.276052 9.99567 0.39658L12.5351 2.82506C12.7909 3.06964 12.79 3.46537 12.5332 3.70894C12.2998 3.93038 11.9351 3.94984 11.6786 3.76779L11.6051 3.70713L10.1749 2.34037L10.1749 10.86C10.1749 11.2052 9.88113 11.485 9.51869 11.485C9.18646 11.485 8.91189 11.2499 8.86843 10.9448L8.86244 10.86L8.86244 2.33904L7.43308 3.70705C7.20062 3.92944 6.83609 3.95038 6.57875 3.76938L6.50501 3.70902C6.27151 3.48763 6.24952 3.14045 6.43957 2.89537L6.50294 2.82514L9.04393 0.39436Z" fill="#343434"/></svg>
                <span>Поделиться фермой</span>
              </button>
              <div :class="{open: shareOpen}" class="list">
                <ShareNetwork v-for="network in networks" :network="network.network" :media="pathToImage + post.video_preview_pic" :key="network.network" :url="domain + $route.path " :quote="post.text.replace(/<\/?[a-zA-Z]+>/gi,'').replace(/&laquo;|&raquo;|&mdash;|&nbsp;/ig,' ')" :description="post.title" :title="post.title">
                  <span>{{ network.name }}</span>
                </ShareNetwork>
                <button @click="copyToClipboard()" type="button">Скопировать ссылку</button>
              </div>
            </div>
          </div>
        </div>
        <div class="wrap__farm-video" id="wrap__farm-video-id">
          <img id="img-picture-hide" :src="pathToImage + post.video_preview_pic" alt="">
          <svg @click="playVideo(post.video_url)" id="img-svg-hide" class="img-svg-play" width="80" height="66" viewBox="0 0 47 33" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M46.9892 11.6302C47.0948 8.5767 46.4265 5.54602 45.0463 2.81959C44.1099 1.70086 42.8103 0.945904 41.3741 0.686256C35.4335 0.147645 29.4682 -0.0731147 23.504 0.0249243C17.5615 -0.0775644 11.6177 0.136071 5.69797 0.664924C4.52759 0.877652 3.44449 1.42618 2.58084 2.24359C0.659327 4.01425 0.445825 7.04358 0.232324 9.60357C-0.0774413 14.2064 -0.0774413 18.8248 0.232324 23.4275C0.29409 24.8684 0.508795 26.2986 0.872828 27.6942C1.13026 28.7717 1.65109 29.7685 2.38869 30.5955C3.25821 31.4562 4.36654 32.036 5.56986 32.2595C10.1728 32.8272 14.8107 33.0625 19.4475 32.9635C26.92 33.0702 33.4745 32.9635 41.2246 32.3662C42.4575 32.1564 43.597 31.5759 44.4912 30.7022C45.0889 30.1047 45.5354 29.3733 45.7936 28.5689C46.5571 26.2278 46.9321 23.7776 46.9038 21.3156C46.9892 20.1209 46.9892 12.9102 46.9892 11.6302ZM18.6789 22.5955V9.39024L31.3182 16.0249C27.774 17.9876 23.0983 20.2062 18.6789 22.5955Z" fill="#FF0000"/></svg>
          <div class="farm-video__block">
            <iframe
              id="iframe-video-block"
              width="560"
              height="315"
              :poster="post.video_preview_pic"
              src=""
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen>
            </iframe>
          </div>
        </div>
      </section>
      <section v-if="post" class="block block-padding-right margin-bottom-new farm-width">
        <div class="wrap__farm-tags">
          <div :class="{active: more[2], hidden: hidden[2]}" class="farm-tags__left">
            <div class="farm-tags__title">Чем занимается ферма:</div>
            <ul class="farm-tags__list" ref="more3">
              <li v-for="type in post.release_types" :key="type.id">
                <span @click="redirectToAllPost(type.id)">
                  {{ type.name }}
                </span>
              </li>
            </ul>
          </div>
          <button v-if="hidden[2]" @click="more[2] = !more[2]" v-html="(more[2] ? 'Свернуть' : 'Показать полностью')" class="moretext mb" type="button"></button>
        </div>
      </section>
      <section v-if="post" class="block block-padding-right margin-bottom-new farm-width">
        <div class="wrap__farm-about">
          <div class="farm-about__left">
            <div class="farm-about__title with_icon">
              <svg width="33" height="28" viewBox="0 0 33 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M33 0.0804614C9.51643 -0.515509 6.44789 2.06703 3.9018 12.278L0 27.9321C31.3996 28.7268 26.5653 22.6942 33 0.0804614Z" fill="#FFD400"/></svg>
              <span>О ферме</span>
            </div>
            <div :class="{active: more[0], hidden: hidden[0]}" class=" hiddentext farm-about__text--wrapper">
              <div class="farm-about__text" v-html="post.text" ref="more1">
              <!--{{ post.text.replace(/<\/?[a-zA-Z]+>/gi,'') }}-->
              </div>
            </div>
            <button v-if="hidden[0]" @click="more[0] = !more[0]" v-html="(more[0] ? 'Свернуть' : 'Показать полностью')" class="moretext" type="button"></button>
            <div class="farm-about__story">
              <div class="farm-about__story-title">Личная история</div>
              <div class="farm-about__story-person">
                <div class="story-person__photo"><img :src="pathToImage + post.history_hero_pic" /></div>
                <div class="story-person__info-wrp">
                  <div class="story-person__name">{{ post.history_hero_name }}</div>
                  <div class="story-person__activity">{{ post.history_hero_post }}</div>
                </div>
              </div>
              <div class="farm-about__story-text">
                <div :class="{active: more[1], hidden: hidden[1]}" class="hiddentext">
                  <div class="story-text__quote" v-html="post.history_hero_text" ref="more2">
                  <!--{{ post.history_hero_text.replace(/<\/?[a-zA-Z]+>/gi,'') }}-->
                  </div>
                </div>
                <button v-if="hidden[1]" @click="more[1] = !more[1]" v-html="(more[1] ? 'Свернуть' : 'Показать полностью')" class="moretext" type="button"></button>
              </div>
            </div>
          </div>
          <div class="farm-about__rigth">
            <div v-if="post.popular_products.length > 0" class="farm-about__product">
              <div class="product__title">Популярный продукт</div>
              <swiper
                class="product__slider slick-container"
                :slides-per-view="3"
                :space-between="50"
                :options="swiperOption"
              >
                <swiper-slide
                  class="product__slide"
                  v-for="product in post.popular_products"
                  :key="product.name"
                >
                  <div class="product__photo"><img :src="pathToImage + product.pic" /></div>
                  <div class="product__slide-title">{{ product.name }}</div>
                  <div class="product__slide-button"><a target="_blank" :href="product.url_to_shop">Найти в магазине</a></div>
                </swiper-slide >

              </swiper>
              <div class="swiper-pagination swiper-pagination--settings" slot="pagination"></div>
              </div>
          </div>
        </div>
      </section>
      <section v-if="post" class="block block-padding-right margin-bottom-new farm-width">
        <div class="farm-contact__title">Как добраться до фермы</div>
        <div class="wrap__farm-contact">
          <div class="farm-contact__map ">
              <yandex-map
                :coords="[post.map_lat, post.map_lng] "
                style="width: 100%; height: 100%;"
                :zoom="10"
              >
                <ymap-marker
                  marker-id="Ферма"
                  :coords="[post.map_lat, post.map_lng]"
                  :icon="markerIcon"
                />
              </yandex-map>
          </div>
          <div class="farm-contact__address">
            <div class="address__title">Адрес:</div>
            <div class="address__text"><a target="_blank" :href="post.address_url" class="d-block">{{ post.address_name }}</a></div>
            <div class="address__title">Контакты:</div>
            <div class="address__text">
              <div v-html="post.contact_text"></div>
              <!--Москва — <a href="tel:89261677520">8 (926) 167 75 20</a>-->
              <!--<div class="address__text padding-contact">Московская область — <a href="tel:89251006333">8(925) 100 63 33</a></div>-->
              <!--<div class="address__title">Светлана Матюшкина</div>-->
              <!--<div class="address__text address-person">директор фермы</div>-->
              <!--<div class="address__title">Светлана Матюшкина</div>-->
              <!--<div class="address__text padding-contact address-person">директор фермы</div>-->
              <ul class="address__social">
                <li v-if="post.farm_site_url">
                  <a :href="post.farm_site_url" target="_blank">
                    <span><svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.0283 12.0938C17.946 12.1664 17.8963 12.2691 17.891 12.3787C17.8425 13.3913 17.7592 14.4027 17.6413 15.411C17.4872 16.7286 16.4217 17.7783 15.0916 17.9269C11.7357 18.302 8.26417 18.302 4.90832 17.9269C3.57821 17.7783 2.51272 16.7286 2.35861 15.411C1.95761 11.9824 1.95761 8.51886 2.35861 5.09033C2.51272 3.77266 3.57821 2.72304 4.90832 2.57438C7.12084 2.3271 9.38637 2.24297 11.6334 2.32176C11.7957 2.32745 11.9338 2.20399 11.9451 2.04202L11.9942 1.339C11.9972 1.29761 12.0013 1.25666 12.0064 1.21617C12.0308 1.02178 11.896 0.830103 11.7002 0.823167C9.37465 0.740783 7.03268 0.827615 4.74171 1.08367C2.72965 1.30854 1.10532 2.89355 0.868765 4.91608C0.454227 8.46038 0.454227 12.0409 0.868765 15.5852C1.10532 17.6077 2.72965 19.1928 4.74171 19.4176C8.20828 19.8051 11.7916 19.8051 15.2582 19.4176C17.2703 19.1928 18.8946 17.6077 19.1311 15.5852C19.3001 14.1404 19.4002 12.6896 19.4314 11.2376C19.4354 11.0554 19.2118 10.9646 19.0839 11.0944C18.7437 11.4397 18.3916 11.773 18.0283 12.0938Z" fill="#13A538"/><path d="M5.86716 13.5504C5.64593 13.4895 5.49458 13.2858 5.5001 13.0564L5.55934 10.5925C5.64416 7.06456 8.52848 4.24875 12.0575 4.24875L13.3149 4.24875C13.3412 3.62502 13.377 3.0016 13.4224 2.37869L13.4903 1.44805C13.5317 0.880107 14.1649 0.562621 14.6448 0.869236C16.7157 2.19253 18.5161 3.89762 19.9499 5.89363L20.406 6.52857C20.5313 6.70289 20.5313 6.93767 20.406 7.11199L19.9499 7.74693C18.5161 9.74294 16.7157 11.448 14.6448 12.7713C14.2496 13.0238 13.749 12.8531 13.562 12.4678C13.5227 12.3859 13.4975 12.2915 13.4903 12.1925L13.4224 11.2619C13.3683 10.519 13.3277 9.77537 13.3008 9.03137L12.9467 8.97808C10.6904 8.6385 8.45879 9.72726 7.33775 11.7145L6.43544 13.3141C6.39247 13.3902 6.33148 13.4518 6.26063 13.4951C6.14568 13.5653 6.00392 13.5881 5.86716 13.5504Z" fill="#13A538"/></svg></span>
                    Сайт
                  </a>
                </li>
                <li v-if="post.farm_instagram_url">
                  <a :href="post.farm_instagram_url" target="_blank">
                    <span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99994 6.75005C8.20501 6.75005 6.74994 8.20512 6.74994 10C6.74994 11.795 8.20501 13.25 9.99994 13.25C11.7949 13.25 13.2499 11.795 13.2499 10C13.2499 8.20512 11.7949 6.75005 9.99994 6.75005Z" fill="#13A538"/><path fill-rule="evenodd" clip-rule="evenodd" d="M4.76948 1.08151C8.2176 0.696136 11.7823 0.696136 15.2304 1.08151C17.1288 1.29369 18.66 2.78947 18.8828 4.69452C19.2951 8.21952 19.2951 11.7806 18.8828 15.3056C18.66 17.2106 17.1288 18.7064 15.2304 18.9186C11.7823 19.304 8.2176 19.304 4.76948 18.9186C2.87108 18.7064 1.33989 17.2106 1.11707 15.3056C0.704791 11.7806 0.704791 8.21952 1.11707 4.69452C1.33989 2.78947 2.87108 1.29369 4.76948 1.08151ZM14.9999 4.00005C14.4477 4.00005 13.9999 4.44776 13.9999 5.00005C13.9999 5.55233 14.4477 6.00005 14.9999 6.00005C15.5522 6.00005 15.9999 5.55233 15.9999 5.00005C15.9999 4.44776 15.5522 4.00005 14.9999 4.00005ZM5.24994 10C5.24994 7.37669 7.37659 5.25005 9.99994 5.25005C12.6233 5.25005 14.7499 7.37669 14.7499 10C14.7499 12.6234 12.6233 14.75 9.99994 14.75C7.37659 14.75 5.24994 12.6234 5.24994 10Z" fill="#13A538"/></svg></span>
                    Инстаграм
                  </a>
                </li>
                <li v-if="post.farm_vk_url">
                  <a :href="post.farm_vk_url" target="_blank">
                    <span><svg width="22" height="13" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.5035 12.381H18.222C17.3588 12.381 17.0992 11.682 15.5511 10.1339C14.2 8.83126 13.6292 8.66863 13.2868 8.66863C12.8145 8.66863 12.6847 8.79842 12.6847 9.4505V11.5021C12.6847 12.0573 12.5049 12.3825 11.0553 12.3825C9.64846 12.288 8.28422 11.8606 7.07493 11.1355C5.86564 10.4104 4.84592 9.4084 4.09975 8.21202C2.32828 6.00713 1.0957 3.41934 0.5 0.654424C0.5 0.311963 0.629791 0.00234121 1.28187 0.00234121H3.56182C4.14822 0.00234121 4.35933 0.263487 4.5892 0.86553C5.69633 4.12438 7.58534 6.95789 8.35158 6.95789C8.64556 6.95789 8.77379 6.8281 8.77379 6.0947V2.7389C8.67684 1.20799 7.86369 1.0782 7.86369 0.524632C7.87413 0.378623 7.94104 0.242426 8.05022 0.144922C8.1594 0.0474177 8.30227 -0.00372464 8.44853 0.00234121H12.0326C12.5221 0.00234121 12.6847 0.246286 12.6847 0.832691V5.3613C12.6847 5.85076 12.8958 6.01339 13.0428 6.01339C13.3368 6.01339 13.5635 5.85076 14.1015 5.31283C15.2569 3.90381 16.2009 2.33401 16.9037 0.652861C16.9756 0.450924 17.1115 0.278036 17.2908 0.160581C17.4701 0.0431254 17.6829 -0.0124476 17.8967 0.00234121H20.1782C20.8616 0.00234121 21.007 0.344802 20.8616 0.832691C20.032 2.6911 19.0055 4.45518 17.7997 6.0947C17.5542 6.47 17.4557 6.66547 17.7997 7.10488C18.0265 7.44734 18.8256 8.11506 19.3635 8.74994C20.1452 9.52969 20.7944 10.4319 21.2853 11.4208C21.4808 12.0557 21.154 12.381 20.5035 12.381Z" fill="#13A538"/></svg></span>
                    ВКонтакте
                  </a>
                </li>
                <li v-if="post.farm_fb_url">
                  <a :href="post.farm_fb_url" target="_blank">
                    <span><svg width="24" height="24" viewBox="0 0 24 24" fill="none" style="fill: #13A538" xmlns="http://www.w3.org/2000/svg"><path d="M9.19795 21.5H13.198V13.4901H16.8021L17.198 9.50977H13.198V7.5C13.198 6.94772 13.6457 6.5 14.198 6.5H17.198V2.5H14.198C11.4365 2.5 9.19795 4.73858 9.19795 7.5V9.50977H7.19795L6.80206 13.4901H9.19795V21.5Z" /></svg></span>
                    Фейсбук
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section v-if="post" class="block block-padding-right margin-bottom-new farm-width">
        <div class="wrap__farm-photos">
          <div class="farm-photos__heading">
            <div class="farm-photos__title">Фото с поездки</div>
            <div class="farm-photos__instagram">Смотреть больше фото в <a href="https://instagram.com/danilenko.al?utm_medium=copy_link" target="_blank">Инстаграм</a></div>
          </div>
          <div class="farm-photos__list-photos">
            <div
              class="list-photos__photo-block"
              v-for="(img, index) in post.images.slice(0, imgBreakpoint)"
              :key="img.pic"
              @click="showModal(index)"
            >
              <img :src="pathToImage + img.pic" />
            </div>
            <div class="list-photos__photo-block photo-block__link-more">
              <a
                @click="getMoreImg"
                v-if="post.images.length > 5 &&  post.images.length >= this.imgBreakpoint"
              >
                <span>Показать ещё</span>
              </a>
            </div>
          </div>
          <modal-window :swiperImages="swiperImages" ref="modal"></modal-window>
        </div>
      </section>
      <section v-if="post" class="block block-padding-right margin-bottom-new farm-width wrapper__edition-swiper">

        <div class="slider-edition__heading">
          <div class="slider-edition__title">Другие выпуски</div>
          <div class="slider-edition__more">
            <router-link class="" to="/allposts">Смотреть все</router-link>
            <!--<a href="/allposts">Смотреть все</a>-->
          </div>
        </div>
        <div class="swiper edition-swiper recomended-post-list">
          <div class="editions__list swiper-wrapper">
            <div
              class="maxim-class"
              v-for="item in postsBlock.slice(0, 6)"
              :key="item.id"
            >
              <div
                class="maxim"
                v-if="item.id == post.id"
              >

              </div>
              <div
                class="editions__list--wrapper"
                v-else>
                <div
                  class="editions__block swiper-slide"
                >
                  <div
                    class="editions__cover"
                    @click="openPost(item.id)"
                    :post="item.id"
                  >
                    <a @click="openPost(item.id)" class="editions__cover-link">
                      <img :src="pathToImage + item.video_preview_pic" alt="«Аквалайн»: природная вода из подземного озера" />
                    </a>
                  </div>
                  <a
                    @click="openPost(item.id)"
                    class="editions__title"
                  >
                    {{ item.title }}
                  </a>
                  <div class="editions__info-time">
                    Выпуск от {{ item.date.split('-').reverse().join('.') }}
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="editions__list--gradient"></div>
          <div class="swiper-scrollbar"></div>
        </div>
      </section>
      <FormBlock/>
      <ContactsExt />
    </div>

  </section>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import Loader from '../../components/UI/Loader.vue';
import CustomLink from '../../components/UI/CustomLink.vue';
import { changeUrl } from '@/helpers/commonHelpers';
import Feedback from "@/components/Feedback.vue";
import News from "@/views/mainPage/components/News.vue";
import ModalWindow from '@/components/modal-window.vue'
// import { Navigation, Pagination, Scrollbar, A11y} from 'swiper';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
// import { Swiper, SwiperSlide } from 'swiper/vue';
// import Swiper from "swiper"
import FormBlock from "@/components/FormBlock.vue";
import {yandexMap, ymapMarker} from 'vue-yandex-maps'
import ContactsExt from "@/components/ContactsExtBlock.vue"
export default {
  name: 'Post',
  // ytp-cued-thumbnail-overlay-image
  data () {
    return {
      domain: 'https://fondsvoe.ru/',
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
          // dynamicBullets: true
        }
      },
      chosenPhoto: null,
      imgBreakpoint: 5,
      pathToImage: 'https://fondsvoe.ru/media/static/images/',
      loading: true,
      post: null,
      swiperImages: null,
      // coords: ,
      markerIcon: {
        layout: 'default#imageWithContent',
        imageHref: 'https://fondsvoe.ru/media/static/images/icon-metka.png',
        imageSize: [29, 24],
        imageOffset: [0, 0],
        // content: 'Ферма',
        contentOffset: [0, 15],
        contentLayout: '<div style="background: red; width: 50px; color: #FFFFFF; font-weight: bold;">$[properties.iconContent]</div>'
      },
      more: {0: false, 1: false, 2: false},
      hidden: {0: false, 1: false, 2: false},
      networks: [
        { network: 'vk', name: 'ВКонтакте'},
        { network: 'facebook', name: 'Facebook'},
        { network: 'odnoklassniki', name: 'Одноклассники'}
      ],
      shareOpen: false
    }
  },
  computed: {
    ...mapState('posts', ['posts']),
    ...mapState('posts', ['tag']),
    postsBlock () {
      return this.posts.results
    },
  },
  watch: {
    $route: async function () {
      this.loading = true
      this.post = await this.getPost(this.$route.params.id)
      this.loading = false
    }
  },
  mounted () {
    this.loadPost()
    if(window.attachEvent) {
      window.attachEvent('onresize', () => {
        this.setHiddensText()
      })
    } else if(window.addEventListener) {
      window.addEventListener('resize', () => {
        this.setHiddensText()
      }, true)
    }
    document.addEventListener('click', (e) => {
      if(!e.target.closest('.toggle')) {
        this.shareOpen = false
      }
    })
  },
  methods: {
    copyToClipboard() {
      this.$copyText(window.location.href)
    },
    showModal: function (index) {
      this.chosenPhoto = index;
      this.swiperImages = this.post.images;
      let photo = this.swiperImages[0];
      this.swiperImages[0] = this.swiperImages[index];
      this.swiperImages[index] = photo;
      // [a, b] = [b, a];
      this.$refs.modal.show = true
    },
    async loadPost() {
      // window.onload = function() {
      //   let image = document.getElementsByClassName('.ytp-cued-thumbnail-overlay-image');
      //   image.style.backgroundImage = 'asdasd';
      // },
      await this.resetPosts()
      await this.getPosts({ tag: this.tag, page: this.page, pageSize: 8 })
      this.post = await this.getPost(this.$route.params.id)
      // this.post.text = changeUrl(this.post.text)
      this.post = this.post[0]
      // this.coords = this.coords.push(this.post.map_lng)
      // this.coords = this.coords.push('777')
      // this.post = changeUrl(this.post.text)
      // this.changeImage();
      this.loading = false
      this.setHiddensText()
    },
    ...mapActions('posts', ['getPost']),
    ...mapActions('posts', ['getPosts']),
    ...mapMutations(['setTag']),
    ...mapMutations('posts', ['resetPosts']),
    // choiceHash (el) {
    //   this.setTag(el)
    //   this.$router.push('/post')
    // },
    redirectToAllPost (typeID) {
      this.$router.push("/allposts?catType=" + typeID);
    },
    openPost(id) {
      // this.$router.push(`/post/${id}`)
      let routeUrl = '/post/' + id
      window.open(routeUrl, '_blank');
      window.location.reload()
    },
    playVideo(urlVideo) {
      // alert(urlVideo)

      // let fullUrlVideo = urlVideo + '?autoplay=1';
      let resUrlVideo = urlVideo.split("youtu.be/");

      let readyUrl = "https://www.youtube.com/embed/" + resUrlVideo[1] + '?autoplay=1';

      let imgWrap = document.getElementById('wrap__farm-video-id')
      let image = document.getElementById('img-picture-hide');
      let svg = document.getElementById('img-svg-hide');
      let video = document.getElementById('iframe-video-block');
      image.style.display = 'none'
      svg.style.display = 'none'

      imgWrap.classList.remove('wrap__farm-video');

      video.src = readyUrl;
      // console.log(readyUrl);
      //https://youtu.be/7XaU0IVYSgA
      // https://youtu.be/embed/7XaU0IVYSgA?autoplay=1
      // video.src = 'https://www.youtube.com/embed/o-4S10a7vrk?autoplay=1'
      // alert('hi')
    },
    changeImage () {
      // alert('gu')
      //let image = document.getElementsByClassName('ytp-cued-thumbnail-overlay-image');
      // image.style.display = 'none'
      // image.style.color = ''
    },
    getMoreImg () {
      this.imgBreakpoint += 5
      // return this.postsBreakpoint
    },
    changeUrl,
    setHiddensText() {
      let interval = setInterval(() => {
        if(this.$refs.more1) {
          if(this.$refs.more1.clientHeight > 120) {
            this.hidden[0] = true
          } else {
            this.hidden[0] = false
          }
          clearInterval(interval)
        }
      }, 100)
      let interval2 = setInterval(() => {
        if(this.$refs.more2) {
          if(this.$refs.more2.clientHeight > 120) {
            this.hidden[1] = true
          } else {
            this.hidden[1] = false
          }
          clearInterval(interval2)
        }
      }, 100)
      let interval3 = setInterval(() => {
        if(this.$refs.more3) {
          if(this.$refs.more3.clientHeight > 272) {
            this.hidden[2] = true
          } else {
            this.hidden[2] = false
          }
          clearInterval(interval3)
        }
      }, 100)
    }
  },
  created(){
    this.changeImage()
  },
  components: { Swiper, SwiperSlide, ModalWindow, yandexMap, ymapMarker, Feedback, FormBlock, Loader, CustomLink, News, ContactsExt },
};
</script>

<style scoped lang="scss">
  .img-svg-play {
    z-index: 7;
    position: absolute;
    left: auto;
    top: auto;
  }
  .product__slide-button:hover a{
    background: #FFD400;
  }
  .farm-tags__list li span:hover {
    box-shadow: inset 0 0 0 1px #13a538;
  }
  @media (max-width: 776px) {
    .img-svg-play {
      width: 55px;
    }
  }
</style>
