<template>
  <div v-if="show" class="modal-shadow" @click.self="closeModal">
    <div class="modal">
      <div class="modal-close" @click="closeModal">&#10006;</div>
      <slot name="body">
        <div class="modal-content">
          <swiper
            class="product__slider slick-container"
            :slides-per-view="1"
            :options="swiperOption"
          >
            <swiper-slide
              class="product__slide"
              v-for="image in swiperImages"
              :key="image"
            >
              <div class="post-images__slider"><img :src="pathToImage + image.pic" /></div>
            </swiper-slide >
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
  name: "ModalWindow",
  data: function () {
    return {
      pathToImage: 'https://fondsvoe.ru/media/static/images/',
      swiperOption: {
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,

          // dynamicBullets: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      show: false
    }
  },
  computed: {

  },
  props: ['swiperImages'],
  methods: {
    closeModal: function () {
      this.show = false
    }
  },
  components: { Swiper, SwiperSlide }
}
</script>

<style lang="scss">
.modal-shadow {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.post-images__slider {
  display: flex;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;

  @media (max-width: 1024px) {
    max-width: 100%;
    align-items: center;
    height: 100%;
  }

}

.post-images__slider img {
  max-height: 605px;
  border-radius: 10px;
}

.modal .swiper-button-prev,
.modal .swiper-button-next {
  border-radius: 50%;
  color: #fff;
  background: #000000ab;
  width: 60px;
  height: 60px;
  font-size: 18px;
}

.modal .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after,
.modal .swiper-button-prev:after, .swiper-container-rtl .swiper-button-prev:after
{
  font-size: 16px;
}

.modal {
  border-radius: 8px;
  padding: 15px;
  //max-height: 805px;
  max-width: 800px;
  //height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1024px) {
    max-width: 100%;
  }

  &-close {
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 23px;
    right: 25px;
    width: 60px;
    height: 60px;
    cursor: pointer;
    z-index: 1001;
    background: #000000ab;
  }

  &-title {
    color: #0971c7;
  }

  &-content {
    margin-bottom: 20px
  }

  &-footer {
    &__button {
      background-color: #0971c7;
      color: #fff;
      border: none;
      text-align: center;
      padding: 8px;
      font-size: 17px;
      font-weight: 500;
      border-radius: 8px;
      min-width: 150px;
    }
  }
}
</style>
